<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import FormModal from './form/FormModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Api from '@/services/api'
import sortIcon from '@/assets/icons/sort-icon.svg'
import draggable from 'vuedraggable'
import { debounce, sumBy } from 'lodash'

export default {
  name: 'WeeklyExerciseList',
  components: {
    FormModal,
    ConfirmModal,
    draggable,
  },
  props: {
    programId: Number,
  },
  computed: {
    ...mapState({
      weeklyExerciseList: (state) => state.weeklyExercise.weeklyExerciseList,
    }),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  data() {
    return {
      icons: {
        mdiPlus,
        sortIcon,
      },
      openForm: false,
      selectedItem: null,
      pendingAction: '',
      selectedItemLoading: false,
      showDuplicateModal: false,
      showDeleteModal: false,
      list: [],
      drag: false,
    }
  },
  async mounted() {
    this.resetState()
    debounce(this.getWeeklyExercises, 300, { maxWait: 1000 })()
  },
  methods: {
    ...mapActions({
      getWeeklyExerciseList: 'weeklyExercise/getWeeklyExerciseList',
    }),
    ...mapMutations({
      setWeeklyExerciseList: 'weeklyExercise/setWeeklyExerciseList',
      resetState: 'weeklyExercise/resetState',
      setLoading: 'weeklyExercise/setLoading',
    }),
    async getWeeklyExercises() {
      if (this.weeklyExerciseList.loading) return

      // await this.getWeeklyExerciseList({
      //   programId: this.programId,
      //   params: {},
      // })

      this.setWeeklyExerciseList({
        ...this.weeklyExerciseList,
        loading: true,
      })
      const { data } = await Api.get(
        `admin/programs/${this.programId}/weekly-exercises?includes[]=dailyExercises`
      )
      this.setWeeklyExerciseList({
        ...this.weeklyExerciseList,
        data: data?.data || [],
      })

      this.list = data?.data.map((item) => {
        // let categories = []

        // item.daily_exercises.forEach((day) => {
        //   if (!categories.find((c) => c.id === day.category.id)) {
        //     categories.push(day.category)
        //   }
        // })

        return {
          ...item,
          // categories,
          daysCount: item.daily_exercises.length,
          exercisesCount:
            sumBy(
              item.daily_exercises,
              (exercise) => exercise.contents.length
            ) || 0,
        }
      })

      this.setLoading(false)

      this.$swal({
        position: 'top-end',
        icon: 'success',
        title: 'Weekly exercises loaded successfully',
        showConfirmButton: true,
        timer: 1500,
      })
    },
    onInitCreateNew() {
      this.$refs.form.setFormData({
        daily_exercises: [],
        week: this.weeklyExerciseList.data.length + 1,
      })

      this.openForm = true
    },
    selectItem(item, action = '') {
      this.selectedItem = item

      if (action === 'duplicate') {
        this.showDuplicateModal = true
      }

      if (action === 'delete') {
        this.showDeleteModal = true
      }
    },
    onInitEdit(item) {
      this.$refs.form.setFormData({ ...item })

      this.openForm = true
    },
    async onDragEnd() {
      this.drag = false

      await Api.post(
        `admin/programs/${this.programId}/weekly-exercises/bulk-update`,
        {
          weekly_exercises: this.list.map((item, rank) => ({
            id: item.id,
            week: rank + 1,
          })),
        }
      )

      await this.getWeeklyExercises()
    },
    async handleDuplicateConfirm() {
      this.selectedItemLoading = true
      this.pendingAction = 'duplicate'

      try {
        await Api.post(`programs/week/${this.selectedItem?.id}/duplicate`)
        await this.getWeeklyExercises()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Week successfully duplicated.',
        })
      } catch (error) {
      } finally {
        this.selectedItem = null
        this.selectedItemLoading = false
        this.showDuplicateModal = false
      }
    },
    async handleDeleteConfirm() {
      this.selectedItemLoading = true
      this.pendingAction = 'delete'

      try {
        await Api.delete(
          `program/${this.programId}/weekly-exercises/${this.selectedItem?.id}`
        )

        await this.getWeeklyExercises()

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Week successfully deleted.',
        })
      } catch (error) {
      } finally {
        this.selectedItem = null
        this.selectedItemLoading = false
        this.showDeleteModal = false
      }
    },
  },
}
</script>

<template>
  <div>
    <form-modal
      ref="form"
      v-model="openForm"
      :program-id="programId"
      @reload="getWeeklyExercises"
    />

    <div>
      <v-btn outlined color="primary" large @click="onInitCreateNew">
        <v-icon color="primary">
          {{ icons.mdiPlus }}
        </v-icon>
        <span class="text-sm font-semibold">Week</span>
      </v-btn>
    </div>

    <!-- <div class="mt-5">
      <template>
        <v-simple-table class="elevation-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 24px"></th>
                <th>
                  <span
                    class="text-left font-medium text-base py-4 text-222222"
                  >
                    Week
                  </span>
                </th>
                <th class="text-left font-medium text-base py-4 text-222222">
                  <span
                    class="text-left font-medium text-base py-4 text-222222"
                  >
                    Categories
                  </span>
                </th>
                <th class="text-left font-medium text-base py-4 text-222222">
                  <span
                    class="text-left font-medium text-base py-4 text-222222"
                  >
                    Actions
                  </span>
                </th>
              </tr>
            </thead>
 -->

    <draggable
      tag="ul"
      v-model="list"
      class="mt-5 pl-0"
      v-bind="dragOptions"
      @start="drag = true"
      @end="onDragEnd"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <li
          v-for="(item, i) in list"
          :key="`weekly-exercise-list-${i}`"
          class="weekly-exercises-item"
        >
          <div style="width: 50%; display: flex; align-items: center">
            <div class="mr-5">
              <v-img
                height="20"
                width="12"
                src="@/assets/icons/sort-icon.svg"
              />
            </div>

            <div>
              <p class="mb-1 text-sm font-medium text-222222">
                Week {{ item.week }}
              </p>
              <p class="text-xs text-222222 mb-0">
                {{ item.daysCount }} DAYS, {{ item.exercisesCount }} EXERCISES
              </p>
            </div>
          </div>

          <div style="width: 25%">
            <v-chip
              v-for="category in item.categories"
              :key="`weekly-exercise-list-${i}-categories-${category.id}`"
            >
              {{ category.label }}
            </v-chip>
          </div>

          <div style="width: 25%">
            <v-btn outlined @click="onInitEdit(item)">
              <span class="text-sm">Edit</span>
            </v-btn>

            <v-btn
              outlined
              class="text-xs mx-2"
              @click="selectItem(item, 'duplicate')"
              :loading="
                selectedItem?.id === item.id &&
                selectedItemLoading &&
                pendingAction == 'duplicate'
              "
            >
              <span class="text-sm">Duplicate</span>
            </v-btn>

            <v-btn
              outlined
              class="text-xs"
              @click="selectItem(item, 'delete')"
              :loading="
                selectedItem?.id === item.id &&
                selectedItemLoading &&
                pendingAction == 'delete'
              "
            >
              <span class="text-sm">Delete</span>
            </v-btn>
          </div>
        </li>
      </transition-group>
    </draggable>

    <ConfirmModal
      :loading.sync="selectedItemLoading"
      title="Duplicate week"
      message="Do you want to duplicate this week?"
      v-model="showDuplicateModal"
      @cancel="showDuplicateModal = false"
      @confirm="handleDuplicateConfirm"
    />

    <ConfirmModal
      :loading.sync="selectedItemLoading"
      title="Delete week"
      message="Do you want to delete this week?"
      v-model="showDeleteModal"
      @cancel="showDeleteModal = false"
      @confirm="handleDeleteConfirm"
    />
  </div>
</template>

<style scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #3b55ce;
}

.weekly-exercises-item {
  cursor: move;
  display: flex;
  align-items: center;
  border-top: 1px solid rgb(230, 230, 233);
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
