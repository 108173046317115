import Api from '@/services/api'

const defaultState = {
  loading: false,
  params: { page: 1, per_page: 10 },
  data: [],
}

export default {
  namespaced: true,
  state: {
    weeklyExerciseList: defaultState,
  },

  mutations: {
    setWeeklyExerciseList(state, payload) {
      state.weeklyExerciseList = payload
    },
    resetState(state) {
      state.weeklyExerciseList = defaultState
    },
    setLoading(state, loading = false) {
      state.weeklyExerciseList.loading = loading
    },
  },

  actions: {
    async getWeeklyExerciseList({ commit, state }, { programId, params }) {
      if (state.weeklyExerciseList.loading) {
        return
      }

      commit('setWeeklyExerciseList', {
        ...state.weeklyExerciseList,
        loading: true,
      })

      const { data } = await Api.get(
        `admin/programs/${programId}/weekly-exercises?includes[]=dailyExercises`
      )

      const updatedParams = {
        ...params,
        includes: ['dailyExercises'],
      }

      commit('setWeeklyExerciseList', {
        loading: false,
        params: updatedParams,
        data: data.data,
      })
    },
  },
}
